import React, { useEffect, useState, useRef } from 'react';
import {
    FormHelperText, Box, Grid, FormControl, TextField, Button, Card, InputLabel, CardContent, Select, OutlinedInput, MenuItem,
    Avatar,InputAdornment,IconButton,Radio, RadioGroup, FormControlLabel,FormLabel,Checkbox
} from '@mui/material';
import Typography from '@mui/joy/Typography';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AuthUser from "../../Components/Auth/AuthUser";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import RegisterFooter from './RegisterFooter';
import LoginHeader from '../../Components/LoginHeader';
import Backdrop from '@mui/material/Backdrop';
import Loader from '../../Components/Loader/Loader';
import bulletImage from '../../assets/images/ulli.png';
import { withStyles } from '@material-ui/core/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputMask from 'react-input-mask';
import '../../Css/Custom.css';
import { useLocation } from 'react-router-dom';
import Moment from 'moment';
import dayjs from 'dayjs';
import config from '../../AppConfig';
import LogoEdit from '../../Components/Cobrand/LogoEdit';
// import ReCAPTCHA from "react-google-recaptcha";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DialogContentText from '@mui/material/DialogContentText';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';


let gUserName = null;
let gUserPassword = null;
let gClientConcern = null;
let gdoLater = false;
let linkedAccFlag = false;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];
const CustomBullet = withStyles({
    root: {
        backgroundColor: 'transparent',
        backgroundImage: `url(${bulletImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',

    },
    custombullet: {
        listStyleImage: `url(${bulletImage})`
    }
})(Avatar);
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
function Register(props) {
    const dispatch = useDispatch();
    const captchaRef = useRef(null)
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const navigate = useNavigate();
    const [isLevelUpScoreTypeSoftware, setIsLevelUpScoreTypeSoftware] = useState(false);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const { http, httpFormData } = AuthUser();
    const [sumbitbtntext, setSumbitbtntext] = useState('Get Started');
    const [successMessage, setSuccessMessage] = useState();
    const [otpstatus, setOtpstatus] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoader] = useState('');
    const [refer, setRefer] = useState();
    const referral_code = useParams();
    const { referencecode } = useParams();
    const { softwarename } = useParams();
    const [fieldError, setFieldError] = useState([]);
    const [email, setEmail] = useState();
    const [checkEmailStatus, setCheckEmailStatus] = useState(false);
    const [checkCompanyStatus, setCheckCompanyStatus] = useState(false);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [states, setStates] = useState([]);
    const [stateCode, setStateCode] = useState('');
    const [disable, setDisable] = useState(false);
    const [software, setSoftware] = useState([]);
    const [checkOtherStatus, setCheckOtherStatus] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [opens, setOpens] = useState(false);
    const [openEmailApproved, setOpensEmailApproved] = useState(false);
    const [openCongration, setCongration] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const [currentDate, setCurrentDate] = useState("");
    const [formatedCurrentDate, setFormatedCurrentDate] = useState("");
    const [minDate, setMinDate] = useState("");
    const [uploadLogo, setUploadLogo] = useState(false);
    const [imageUrl, setImageUrl] = useState(config.BASE_URL+'genericlogo.png');
    const [stampText, setStampText] = useState('YOUR COMPANY NAME HERE');
    const [flag, setFlag] = useState(0);
    const [logoEditResLogo, setLogoEditResLogo] = useState(null);
	const [logoEditResLogoType, setLogoEditResLogoType] = useState('defaultLogo');
    const [emailButton, setEmailButton] = useState(false);

    //prodigy setup variables
    const [accountConfirmationOpen, setAccountConfirmationOpen] = React.useState(false);
	const [accountConnectOpen, setAccountConnectOpen] = React.useState(false);
    const [marketingType, setMarketingType] = useState('');
    const [marketingTypeInText, setMarketingTypeInText] = useState('');
    const [createAccountConfirmationOpen, setCreateAccountConfirmationOpen] = React.useState(false);
    const [validateopen, setValidateOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    // const [linkedAccFlag, setLinkedAccFlag] = React.useState(false);
    const [formValues, setFormValues] = React.useState([]);
    const [userName, setUserName] = React.useState(null);
    const [userPassword, setUserPassword] = React.useState(null);
    const [softwareAccount, setSoftwareAccount] = React.useState(false);
    const [createAccountFlag, setCreateAccountFlag] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
	const [userId, setUserId] = React.useState('');
	const [dolaterFlag, setDolaterFlag] = React.useState(false);
    const [createAccountOpen, setCreateAccountOpen] = React.useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const [completedPSFlow,setCompletedPSFlow] = React.useState(false);
    const [checkboxValue,setCheckboxValue] = React.useState(false);
    // const queryParams = new URLSearchParams(location.search);

    const handleCheckboxChange = (event) => {
        console.log("event.target.checked:" +event.target.checked);
        setCheckboxValue(event.target.checked);
        if(event.target.checked == true) {
            setCompletedPSFlow(true);
        }else{
            setCompletedPSFlow(false);
        }
        // Perform any additional actions based on the checkbox state here
      };

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleValidateClose = () => {
        setErrorMsg("");
        setValidateOpen(false);
    };

    const cancelValidation = () => {
        handleValidateClose();
        setAccountConfirmationOpen(true);
    };

    const handleCreateNewAccountClose = () => {
        setErrorMsg("");
        setCreateAccountOpen(false);
    };

    const cancelCreateNewAccount = () => {
        handleCreateNewAccountClose();
        setAccountConfirmationOpen(true);
    };

    const createAccount = async() => {
        setCreateAccountConfirmationOpen(false);
		setCreateAccountFlag(true);
		setCreateAccountOpen(true);
		// submitRequest(formValues);
    }

	const accountConnectConfirmation = async() => {
        setAccountConfirmationOpen(false);
		setSoftwareAccount(true);
		setAccountConnectOpen(true);
        // setValidateOpen(true);
		// handleAccountConfirmation()
    }

    const handleAccountConfirmation = async() => {
        // setAccountConfirmationOpen(false);
		setDolaterFlag(false);
		gdoLater = false;
		setAccountConnectOpen(false);
		setSoftwareAccount(true);
        setValidateOpen(true);
    }

	const closeAccountCofirmation = () => {
        setAccountConfirmationOpen(false);
        setCreateAccountConfirmationOpen(true);
		setSoftwareAccount(false);
        // setCompletedPSFlow(true);
    };

    const closeAccountConnect = () => {
		setDolaterFlag(true);
		gdoLater = true;
        setAccountConnectOpen(false);
		setSoftwareAccount(true);
		submitRequest(formValues);
    };

    const closeCreateAccountCofirmation = () => {
        setCreateAccountConfirmationOpen(false);
		setCreateAccountFlag(false);
        linkedAccFlag = false;
		submitRequest(formValues);
    };

	const checkSoftwareUsed = async(values) => { 
		http.post("/checkSoftwareUsedClientStatus", {
			software:values.SoftwareUsed
		})
		.then((res) => {
			if (res.data.success === true) {
				setMarketingType(res.data.data.link_name == "ProdigySurge" || res.data.data.link_name == "ProdigySurgePromo" ? "ProdigySurge" : res.data.data.link_name);
				setMarketingTypeInText(res.data.data.link_name);
				// setLinkedAccFlag(true);
                linkedAccFlag = true;
                if(completedPSFlow == true) {
                    setAccountConfirmationOpen(false);
                    submitRequest(values);
                    console.log("in first if");
                }else{
                    setAccountConfirmationOpen(true);
                    console.log("in else");
                }
			}else{
				// setLinkedAccFlag(false);
                linkedAccFlag =false;
				submitRequest(values);
                console.log("in last else");
			}
		})
    }

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleEmailExitsClose = () => {
        setOpens(false);
    };
    const handleEmailApprovedClose = () => {
        setOpensEmailApproved(false);
    };
    const handleCongrationClose = () => {
        setOpensEmailApproved(false);
        setCongration(false);

    };

    useEffect(()=>{
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var img = new Image();  
        
        // clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);
        
        var regex = /\s*(?:(\S{16})|([\s\S]{1,16})(?!\S))/g;
        var cname2= stampText.replace(regex, function($0,$1,$2) { return $1 ? $1 + "\n" : $2 + "\n"; } );

        var line=cname2.split("\n");
        var linelength = line.length;

        img.crossOrigin = 'anonymous';
        img.src = config.BASE_URL+'genericlogo.png';
        img.onload = async() => {
            canvas.width = "300";
            canvas.height = "90";
            
            context.drawImage(img, 0, 0);
            context.font = '22px Arial';
            context.fillStyle = 'black';

            var count = 100 / linelength;               
            for(var i=0; i < linelength-1; i++){
                context.fillText(line[i], img.width - 200, (count + (count*i)));
            }
            // context.fillText(stampText, img.width - 200, img.height - 50);
            setImageUrl(canvas.toDataURL('image/png')); 
            setLogoEditResLogo(canvas.toDataURL('image/png'))
        }
    },[stampText])
    const handleStampChange = async(value) => {
        if(flag===0){
            value = value.slice(0, 32);
            setStampText(value);
        }
    };
    const handleUploadLogo = (values) => {
        setUploadLogo(true);
    }
    const handleCloseUploadLogo = () => {
        setUploadLogo(false);
    }
	
    const handleLogoURL = (formData) => {
        setImageUrl(formData.get("imageUrl"));

        setLogoEditResLogo(formData.get("uploadedLogo"))
		setLogoEditResLogoType(formData.get("logoType"))
    }
    const handleEditLogoURLFlag = (value) => {
        setFlag(value);
    }
    // Function to decode base64 string
    const decodeBase64 = (str) => {
        try {
            return atob(str);
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };

    //register function start
    const RegisterSubmit = (values) => {
        var year = new Date(values.YearBusinessStarted).getFullYear();
        console.log(year);
        setFormValues(values);
		checkSoftwareUsed(values);
    }

    const submitRequest = async(values) => {
		document.getElementById("editbutton").style.display = "block";
		setIsSubmitted(true);
		setSumbitbtntext('Please wait...')
		setOpen(true);

		setDisable(true)
		http.post("/register", {
			first_name: values.firstName,
			last_name: values.lastName,
			business_name: values.NameofBusiness,
			company_website: values.CompanyWebsite,
			title: values.Title,
			business_started_year: values.YearBusinessStarted,
			email: values.Email,
			phone_no: values.PhoneNumber,
			address: values.StreetAddress,
			referral_code: refer,
			city: values.City,
			state_code: values.State,
			zip_code: values.ZipCode,
			marketing_type: values.SoftwareUsed,
            uploadedLogo: logoEditResLogo,
            logoType: logoEditResLogoType,
			linked_acc_flag:linkedAccFlag,
			do_later: gdoLater,
			linked_account: softwareAccount,
			username: gUserName,
			create_account: createAccountFlag,
			password: gUserPassword,
			otp: "",
			client_concern: gClientConcern
		})
			.then((res) => {
				console.log(res);
				setDisable(false)
				if (res.data.success === true) {
					document.getElementById("submitbtn").classList.remove("d-none");
					setSuccessMessage(res.data.message);
					setSumbitbtntext("Verify OTP");
					document.getElementById("otpinput").style.display = "block";
					// document.getElementById("captchainput").classList.add('d-none');
					setOtpstatus(true);
					setError("");
					setOpen(false);
					if(softwareAccount == true && gdoLater == false) {
                        setCompletedPSFlow(true);
						setUserId(res.data.user_id);
					}else if(createAccountFlag == true) {
                        setCompletedPSFlow(true);
                    }
				} else {
					if(softwareAccount == true && res.data.message == "Username or password is incorrect of linked account"){
						setValidateOpen(true);
						setErrorMsg(res.data.message);
                        setTimeout(function () {
                            setErrorMsg("");
                        }, 5000);
					}
					setDisable(false);
					setSumbitbtntext('Get Started');
					// setFieldError(res.data.message);
					setError(res.data.message);
					// setLoader('');
					// setSumbitbtntext('Register')
					setOpen(false);
				}
			})
			.catch((error) => {
				console.log("in catch");
				setDisable(false)
				setError(error.message);

				setSuccessMessage("");
				setSumbitbtntext('Get Started')
				setOpen(false);
			});
	}
    //register function ends
    //Verify OTP Function
    const Verifyemail = (values) => {
        var year = new Date(values.YearBusinessStarted).getFullYear();
        console.log(year);
        setDisable(true)
        setIsSubmitted(true);
        if (values.otpinput != '' && values.otpinput != null && values.otpinput != undefined) {
            setSumbitbtntext('Please wait...')
            document.getElementById("submitbtn").classList.add('d-none');
            setOpen(true);
            httpFormData.post('/register', {
                first_name: values.firstName,
                last_name: values.lastName,
                business_name: values.NameofBusiness,
                company_website: values.CompanyWebsite,
                title: values.Title,
                business_started_year: year,
                email: values.Email,
                phone_no: values.PhoneNumber,
                address: values.StreetAddress,
                referral_code: refer,
                city: values.City,
                state_code: values.State,
                zip_code: values.ZipCode,
                marketing_type: values.SoftwareUsed,
                othersofttype: values.othersofttype,
                otp: values.otpinput,
                uploadedLogo: logoEditResLogo,
            	logoType: logoEditResLogoType,
                linked_acc_flag:linkedAccFlag,
                do_later: gdoLater,
				linked_account: softwareAccount,
				username: userName,
				create_account: createAccountFlag,
				password: userPassword,
				user_id: userId,
				client_concern: gClientConcern
            }).then((res) => {
                setDisable(false)
                if (res.data.success === true) {
                    // document.getElementById("signupform").style.display = "none";
                    // document.getElementById("signupcomplete").style.display = "block";
                    setSuccessMessage(res.data.message);
                    setError("");
                    setOpen(false)
                    navigate('/thankyou', { state: { name: values.firstName ,url:res.data.data,active:2} });
                } else {
                    setSumbitbtntext("Verify OTP");
                    setDisable(false)
                    setFieldError(res.data.message);
                    setError(res.data.message);
                    setSuccessMessage("");
                    setOpen(false)
                }
            }).catch(error => {
                setDisable(false)
                // document.getElementById("submitbtn").classList.remove('d-none');
                setError(error.message);
                setSuccessMessage("");
                setOpen(false)
            });
        } else {
            setDisable(false)
            setError("Otp is required");
            setSuccessMessage("");
            setOpen(false)
        }
    }


    const SignupSchema = Yup.object().shape({

        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your full Name.'),

        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your last Name.'),

        Email: Yup.string()
            // .email('Invalid email format.')
            // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Please enter your email address.'),

        PhoneNumber: Yup.string()
            .min(14, 'Must be 10 digits.')
            .max(14, 'Must be 10 digits.')
            .required('Please enter your mobile number.'),

        ZipCode: Yup.string()
            .min(5, 'Must be in 5 digits.')
            .max(5, 'Wrong pin code.')
            .required('Please enter pin code.'),

        StreetAddress: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your Street Address.'),

        City: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your City.'),

        State: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please select your State.'),

        NameofBusiness: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your Business Name.'),

        YearBusinessStarted: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter year of your business started.')
            .test('is-valid-year', 'Invalid business started year', function(value) {
                const date = new Date(value);
                const year = date.getFullYear();
                const splitYear = formatedCurrentDate.split('-')[2];
                return  year <= splitYear
            }),

        SoftwareUsed: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter year of your business started.'),

        // othersofttype: Yup.string().when("SoftwareUsed", {
        //  is: (SoftwareUsed) => ['Other'].includes(SoftwareUsed),
        //  then: (schema) => schema
        //      .required('Please enter  notes.')

        // }),  




    });

    const accountSchema = Yup.object().shape({
        username: Yup.string()
        .matches(
            /^[A-Za-z0-9_@#$%&()'\-.]+(?: [A-Za-z0-9_@#$%&()'\-.]+)*$/,
            'Please enter a valid User Name'
        )
        .min(6, 'User Name must be at least 6 characters')
        .required('Please enter User Name.'),

		password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Please enter password.'),

		clientConcern: Yup.string()
            .required('Please Choose One.'),
    });

    //Check email is already exist or not;
    const checkEmail = (email) => {
        if(email == '') {
            setCheckEmailStatus(false);
        }
        if (email.includes('@')) {
            // if (refer === 'ProdigySurgePromo') {
            //     http.post("/checkEpicChallenge", {
            //         email: email,
            //     }).then((res) => {
            //         setEmail(email);
            //         if (res.data.message === 'epicEmailExist') {
            //             setCheckEmailStatus(true);
            //             document.getElementById("epicEmailExist").style.display = "block";
            //             document.getElementById("EmailExist").style.display = "none";
            //         } else if (res.data.message === 'NotExist') {
            //             setCheckEmailStatus(true);
            //             document.getElementById("EmailExist").style.display = "block";
            //             document.getElementById("epicEmailExist").style.display = "none";
            //         } else if (res.data.message === 'Approved') {
            //             document.getElementById("epicEmailExist").style.display = "none";
            //             document.getElementById("EmailExist").style.display = "none";
            //             setCheckEmailStatus(true);
            //             setOpensEmailApproved(true);
            //             setOpens(false);
            //         } else if (res.data.message === 'hotlead') {
            //             setCheckEmailStatus(true);
            //             document.getElementById("epicEmailExist").style.display = "none";
            //             document.getElementById("EmailExist").style.display = "none";
            //             setOpensEmailApproved(false);
            //             setOpens(true);
            //         } else {
            //             setCheckEmailStatus(false);
            //             document.getElementById("epicEmailExist").style.display = "none";
            //             document.getElementById("EmailExist").style.display = "none";
            //             setOpensEmailApproved(false);
            //             setOpens(false);

            //         }
            //     })
            // } else {
            http.post("/checkEmail", {
                email: email,
            }).then((res) => {
                if (res.data.success === true) {
                    setCheckEmailStatus(true);
                } else {
                    setCheckEmailStatus(false);
                }
            })
           
        }

    }
    //Check company is already exist or not;
    const checkCompany = (company) => {
        setEmailButton(true);
        http.post("/checkCompany", {
            company: company,
        })
            .then((res) => {
                if (res.data.success === true) {
                    setCheckCompanyStatus(true);
                } else {
                    setCheckCompanyStatus(false);
                }
            })
            setEmailButton(false);
    }

    const checkUsaTLD = (email) => {
        if (email.includes('@')) {
            setEmailButton(true);
            http.post("/checkUsaTLD", {
                email: email,
            })
            .then((res) => {
                if (res.data.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            })
            setEmailButton(false);
        }
    }
    //Fetch software name
    const fetchSoftware = () => {
        http.get('/getSoftwareInfo').then((res) => {
            if (res.data.success === true) {
                setSoftware(res.data.data)
            }
        })
    }

    const fetchState = () => {
        http.get("/fetchStates").then((res) => {
            setStates(res.data.data);
        });
    }
    // const handleChange1 = (event) => {
    //     setStateCode(event.target.value);
    // };

    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/"+zipcode;
        setFieldValue('City','');
        setFieldValue('State','');
        if(zipcode.length == 5)
        {
            setFieldValue('City','...');
            setFieldValue('State','...');
            setOpen(true);
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                setFieldValue('City', res.data.places[0]['place name']);
                setFieldValue('State', res.data.places[0]['state abbreviation']);
                setOpen(false);
            }
            else
            {
                setFieldValue('City','');
                setFieldValue('State','');
                setOpen(false);
            }
        }
    };
    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const epicproParam = queryParams.get('epicpro');
        if (referencecode == "" || referencecode == undefined) {
            if(epicproParam === 'true'){
                localStorage.setItem('origin', 'epicpro');
            }
            
            setRefer(localStorage.getItem('origin'));

                
            
        } else {
            const trimmedString = referencecode.replace(/=+$/, ''); // Remove trailing '=' characters
            if(decodeBase64(trimmedString) == "LevelUpScore" || decodeBase64(trimmedString) == "M2GoatRepairs" || decodeBase64(trimmedString) == "CreditAdmiral" || decodeBase64(trimmedString) == "DisputeFox") {
                setIsLevelUpScoreTypeSoftware(true);
            }
            setRefer(decodeBase64(trimmedString));

        }
        fetchState();
        fetchSoftware();

        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var CurrentFormatedDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY'); 
        setCurrentDate(cDate);
        setFormatedCurrentDate(CurrentFormatedDate)

        const startDate = new Date('1975-01-01');
        startDate.setDate(startDate.getDate());
        var cDate = Moment(startDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setMinDate(cDate);
    }, [])
    const setSoftwareType = (softwareType) => {
        if (softwareType == 'Other') {
            document.getElementById("othersofttypes").style.display = "block";
            setCheckOtherStatus(true);
            // setDisable(true)
        } else {
            document.getElementById("othersofttypes").style.display = "none";
            setCheckOtherStatus(false);
            // setDisable(false)

        }
    }
    const handleothersoft = (event) => {
        if (event != '') {
            setCheckOtherStatus(false);
        } else {
            setCheckOtherStatus(true);
        }
    }
    const handleEditChange = () => {
        setIsSubmitted(false);
        setSumbitbtntext('Get Started')
        document.getElementById("otpinput").style.display = "none";
        document.getElementById("editbutton").style.display = "none";
        setSuccessMessage();
        setOtpstatus(false);

    }
    const JoinEpicPro = (email) => {
        http.post("/epicChallengeUpgrade", {
            email: email,
        })
            .then((res) => {
                if (res.data.success === true) {
                    setCongration(true)
                } else {
                    setCheckCompanyStatus(false);
                }
            })

    }

    return (

        <>
            <LoginHeader  logoName={refer !== '' && refer !== 'mfsnhomepage' ? 'referral' : '' } />

            <Box className="" sx={{ padding: "2%" }}>
                {/* <Grid container>
                    <Card sx={{ width: "100%", minHeight: "100%", borderRadius: "2px" }}>
                        <CardContent>
                            <Grid container direction="row" sx={{ justifyContent: "space-between" }}>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <img src={require("../../assets/images/registration.png")} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={9} lg={9}>
                                    <p className="Register-font4 Text1">Become A <span className="Register-font1">Credit Monitoring Affiliate.</span> </p>
                                    <p className="Register-font2 Text1">No Cost <span className="Register-font4">To Join.</span></p>
                                    <p className="Register-font4 Text1">Get Your Affiliate Links <span className="Register-font2">In Minutes!</span> </p>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid container item direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>                      
                        <Card sx={{ minHeight: "100%" }} variant="outlined">
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography level="h4">Become A <Typography level="h3" className="font1 monitor">Credit Monitoring Affiliate.</Typography></Typography>
                                        <Typography level="h3" className="font2 cost">No Cost <Typography level="h4">To Join.</Typography></Typography>
                                        <Typography level="h4">Get Your Affiliate Links <Typography level="h3" className="font2 cost">In Minutes!</Typography></Typography>
                                        {
                                            refer === "ClientDisputeManager" ?
                                            <Typography level="h4">Monthly Commissions <Typography level="h3" className="font1 monitor">Up To $16</Typography> Per Customer</Typography>
                                            :
                                            null
                                        }
                                    </Grid>
                                </Grid>
                                {
                                    refer === "ClientDisputeManager"
                                    ?
                                    <img src={require("../../assets/images/CDMBannerNew.png")} style={{paddingTop: '2%'}} className="CobrandImgBanner" data-uw-rm-alt-original=""></img>
                                    :
                                    <Grid container item direction="row" mt={3}>
                                        <Grid item lg={12} md={12} sm={12} xs={12} >
                                            <Typography level="h3" className="font1 cost"><span><img src={require("../../assets/images/questionmark.png")} />&nbsp;</span><u style={{ borderColor: "#30BE2D" }}>
                                                Why MyFreeScoreNow?</u></Typography>
                                        </Grid>
                                        {
                                            isLevelUpScoreTypeSoftware ?
                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                    <ul className='custombullet'>
                                                       { 
                                                        refer == "LevelUpScore" ?
                                                        <>
                                                            <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                3 Bureau reports and credit scores for your clients - reports automatically import into your software. We're compatible with 
                                                                Client Dispute Manager, Credified, Credit Admiral, Credit Money Machine Web, Credit Repair Cloud, DisputeFox, M2Genesis,
                                                                Mustard, Phenom, ProdigySurge and others
                                                            </Typography>
                                                            </li>
                                                        </>
                                                        :   
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                3 Bureau reports and credit scores for your clients - reports automatically import into your <b>{refer}</b> software.
                                                                {refer == "DisputeFox" ? "" : " We're compatible with all major CRMs."}                                                                
                                                            </Typography>
                                                        </li>
                                                       }
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                Mobile app of the web site and credit report branded with your company’s logo.
                                                            </Typography>
                                                        </li>
                                                                                                            
                                                        {
                                                            
                                                            <li style={{ justifyContent: 'center' }}>
                                                                <Typography level="body2" fontSize="lg" className="font4" >
                                                                    <span><img height={30} src={require("../../assets/images/EPRLogo.png")} />&nbsp;</span>
                                                                    - Free Access to the first Smart 3B Report Designed by and exclusively for Credit Professionals
                                                                </Typography>
                                                            </li>
                                                        }
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                Plans as low as $19.95/month; includes a 3B report upfront and refresh every 30 days
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                Higher commissions - up to $16 per customer per month plus special monthly incentives!
                                                            </Typography>
                                                        </li>

                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                Referral Program - Get cash and residuals referring other companies to join our affiliate program
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                We're open 7 days/week. Live agent support including messaging us thru Messenger, WhatsApp and Instagram
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                FREE MyFreeScoreNow membership for you*
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                FREE Co-Branded enrollment pages with your logo and your message. Get alerts about changes to your client's scores.
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                Member credentials passed directly to you when clients enroll – No More Failed Logins!
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                Your affiliate link, the web site and credit report(s) translated into 50+ languages!
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                FREE Lead Generation tool branded to your company. “Credit Snapshot” offers free credit report summary. Convert more visitors to your site and social media into customers
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                Powerful website tools and simulators you can use to advise your clients on how to boost their scores
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                APIs to build credit monitoring services into your own product offerings
                                                            </Typography>
                                                        </li>
                                                        <li><Typography level="body2" fontSize="lg" className="font4" >
                                                            Automations for your business and advanced customer reporting portal to track activity
                                                        </Typography>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                            :
                                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                <ul className='custombullet'>
                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >3 Bureau reports and credit scores for your clients - reports automatically import into your software  
                                                                                                    
                                                        {
                                                            refer === "ClientDisputeManager"
                                                            ?
                                                            <b> Client Dispute Manager.</b>
                                                            :                                                        
                                                            ". We're compatible with Client Dispute Manager, Credified, Credit Admiral, Credit Money Machine Web, Credit Repair Cloud, DisputeFox, Mustard, Phenom, ProdigySurge and others"
                                                        }
                                                        </Typography>
                                                    </li>
                                                    {
                                                        refer === "ClientDisputeManager"
                                                        ?
                                                        ""
                                                        :
                                                        <li style={{ justifyContent: 'center' }}>
                                                            <Typography level="body2" fontSize="lg" className="font4" >
                                                                <span><img height={30} src={require("../../assets/images/EPRLogo.png")} />&nbsp;</span>
                                                                - Exclusive Access to the First Interactive Smart Report Designed for Credit Professionals
                                                            </Typography>
                                                        </li>
                                                    }
                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >
                                                            Plans as low as $19.95/month and include a 3B report upfront and refresh every 30 days
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >
                                                            Higher commissions - up to $16 per customer per month
                                                        </Typography>
                                                    </li>

                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >
                                                            Referral Program - Get MORE cash by referring other companies to join our affiliate program
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >
                                                            We're open 7 days/week. Live agent support
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >
                                                            FREE MyFreeScoreNow memberships for you
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >
                                                            Co-branded sites with your logo and your message. Get alerts about changes to your client's scores. Plus member credentials passed directly to you – No More Failed Logins!
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography level="body2" fontSize="lg" className="font4" >
                                                            Credit Snapshot. FREE Lead Generation tool branded to your company. Offers free credit report summary. Convert more visitors to customers
                                                        </Typography>
                                                    </li>
                                                    <li><Typography level="body2" fontSize="lg" className="font4" >
                                                        Powerful website tools and simulators you can use to advise your clients on how to boost their scores
                                                    </Typography>
                                                    </li>
                                                </ul>
                                            </Grid>    
                                        }
                                       
                                    </Grid>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Card sx={{ minHeight: "100%" }} variant="outlined">
                            <CardContent>
                                <Grid id="epicEmailExist" display="none">
                                    <h4 className="rectangle3">
                                        Hi!  We recognize your email as already in our affiliate program system and is a part of ProdigySurge Epic Challenge promotion.
                                        If you know you're fully registered and have an affiliate account with us,
                                        please login to it at: https//myfreescorenow.com/login. You will find your Epic Challenge Links in My Links tab.
                                        If you're not sure you're fully registered to the affiliate program, please call us at 1-888-548-2008.
                                    </h4>
                                </Grid>
                                <Grid id="EmailExist" display="none">
                                    <h4 className="rectangle3">
                                        Hi!  We recognize your email as already in our affiliate program system.
                                        If you know you're fully registered and have an affiliate account with us,
                                        please login to it at: https;//myfreescorenow.com/login.
                                        If you're not sure you're fully registered to the affiliate program, please call us at 1-888-548-2008.
                                    </h4>
                                </Grid>
                               
								<Grid item xs={12}>
                                    <h1 className='Register-font2' mt={3}>Join Now</h1>
                                    </Grid>
                                
                                <Grid noValidate  >
                                    <Formik

                                        initialValues={{
                                            firstName: '',
                                            lastName: '',
                                            NameofBusiness: '',
                                            CompanyWebsite: '',
                                            Title: '',
                                            YearBusinessStarted: '',
                                            Email: '',
                                            PhoneNumber: '',
                                            StreetAddress: '',
                                            MyFreeScoreNowReferralCode: refer,
                                            City: '',
                                            State: '',
                                            ZipCode: '',
                                            SoftwareUsed: softwarename ? softwarename : '',
                                            Question: '',
                                            othersofttype: '',
										
                                        }}
                                        validationSchema={SignupSchema}
                                        onSubmit={(values, errors) => {
                                            otpstatus ? Verifyemail(values) : RegisterSubmit(values);
                                        }}>
                                        {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, isValid, handleSubmit, setFieldValue, setFieldError }) => (
                                            <div>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            required
                                                            name="firstName"
                                                            onChange={handleChange('firstName')}
                                                            value={values.firstName || ""}
                                                            onBlur={handleBlur('firstName')}
                                                            fullWidth
                                                            // size="small"
                                                            id="firstName"
                                                            autoComplete="firstName"
                                                            label="First Name"
                                                            disabled={isSubmitted}
                                                            helperText={touched.firstName && errors.firstName ? errors.firstName : null}
                                                            error={touched.firstName && Boolean(errors.firstName)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            // size="small"
                                                            id="lastName"
                                                            label="Last Name"
                                                            name="lastName"
                                                            autoComplete="lastName"
                                                            onChange={handleChange('lastName')}
                                                            value={values.lastName || ""}
                                                            onBlur={handleBlur('lastName')}
                                                            disabled={isSubmitted}
                                                            helperText={touched.lastName && errors.lastName ? errors.lastName : null}
                                                            error={touched.lastName && Boolean(errors.lastName)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} sx={{ mt: 0.2 }}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            required
                                                            name="Email"
                                                            // onChange={handleChange('Email')}
                                                            onChange={(e) => {
                                                                console.log("field value change");
                                                                handleChange(e);
                                                                checkEmail(e.target.value)
                                                                checkUsaTLD(e.target.value)
                                                            }}
                                                            value={values.Email || ""}
                                                            onBlur={handleBlur('Email')}
                                                            fullWidth
                                                            autoComplete="Emails"
                                                            // size="small"
                                                            id="Email"
                                                            label="Email"
                                                            disabled={isSubmitted}
                                                            // helperText={touched.Email && errors.Email ? errors.Email : null}
                                                            error={touched.Email && Boolean(errors.Email)}
                                                        />
                                                        <p style={{ color: '#d32f2f', fontFamily: "Roboto", fontWeight: 400, fontSize: "0.90rem" }}>{touched.Email && errors.Email ? errors.Email : checkEmailStatus ? "Email id already exists." : checkUsaTldStatus ? "Invalid email format." : ''}</p>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>

                                                        <InputMask
                                                            mask='(999) 999-9999'
                                                            maskChar={null}
                                                            type="text"
                                                            required
                                                            fullWidth
                                                            id="PhoneNumber"
                                                            label="Phone Number"
                                                            name="PhoneNumber"
                                                            onChange={handleChange('PhoneNumber')}
                                                            value={values.PhoneNumber || ""}
                                                            onBlur={handleBlur('PhoneNumber')}
                                                            autoComplete="PhoneNumber"
                                                            disabled={isSubmitted}
                                                            // className={isSubmitted ? ['disabled-input'] : ''}
                                                            sx={isSubmitted ? { backgroundColor: '#e8f0fe', color: '#D3D3D3', cursor: 'not-allowed' } : null}
                                                            inputProps={{ readOnly: isSubmitted }}
                                                            helperText={touched.PhoneNumber && errors.PhoneNumber ? errors.PhoneNumber : null}
                                                            error={touched.PhoneNumber && Boolean(errors.PhoneNumber)}
                                                        >
                                                            {(inputProps) =>
                                                                <TextField
                                                                    label="Phone Number" variant="outlined"
                                                                    {...inputProps}

                                                                    sx={isSubmitted ? { backgroundColor: '#e8f0fe', color: '#D3D3D3', cursor: 'not-allowed' } : null}
                                                                />
                                                            }
                                                        </InputMask>
                                                        
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6} className="registermargin">
                                                        <TextField
                                                            required
                                                            name="StreetAddress"
                                                            onChange={handleChange('StreetAddress')}
                                                            value={values.StreetAddress || ""}
                                                            onBlur={handleBlur('StreetAddress')}
                                                            fullWidth
                                                            autoComplete="StreetAddress"
                                                            // size="small"
                                                            id="StreetAddress"
                                                            label="Street Address"
                                                            disabled={isSubmitted}
                                                            helperText={touched.StreetAddress && errors.StreetAddress ? errors.StreetAddress : null}
                                                            error={touched.StreetAddress && Boolean( errors.StreetAddress)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                    <InputMask
                                                            mask='99999'
                                                            maskChar={null}
                                                            required
                                                            type="text"
                                                            fullWidth
                                                            // size="small"
                                                            id="ZipCode"
                                                            label="Zip Code"
                                                            name="ZipCode"
                                                            onChange={(e) => {
                                                                handleChange('ZipCode')(e);
                                                                fetchLocationInfo(e.target.value, setFieldValue);
                                                            }}
                                                            value={values.ZipCode || ""}
                                                            onBlur={handleBlur('ZipCode')}
                                                            autoComplete="ZipCode"
                                                            disabled={isSubmitted}
                                                            inputProps={{ readOnly: isSubmitted }}
                                                            helperText={touched.ZipCode && errors.ZipCode ? errors.ZipCode : null}
                                                            error={touched.ZipCode && Boolean(errors.ZipCode)}
                                                            sx={isSubmitted ? { backgroundColor: '#e8f0fe', color: '#D3D3D3', cursor: 'not-allowed' } : null}
                                                            >
                                                            {(inputProps) =>
                                                                <TextField
                                                                    label="Zip Code" variant="outlined"
                                                                    {...inputProps}
                                                                    sx={isSubmitted ? { backgroundColor: '#e8f0fe', color: '#D3D3D3', cursor: 'not-allowed' } : null}
                                                                />
                                                                
                                                            }
                                                        
                                                        </InputMask>
                                                       
                                                    </Grid>
                                                   
                                                </Grid>
                                                <Grid container spacing={2} sx={{ mt: 0.2 }}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            required
                                                            name="City"
                                                            autoComplete="City"
                                                            onChange={handleChange('City')}
                                                            value={values.City || ""}
                                                            onBlur={handleBlur('City')}
                                                            fullWidth
                                                            // size="small"
                                                            id="City"
                                                            label="City"
                                                            disabled={isSubmitted}
                                                            helperText={touched.City && errors.City ? errors.City : null}
                                                            error={touched.City && Boolean(errors.City)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                    <FormControl fullWidth
                                                error={touched.State && Boolean(errors.State)}
                                            >
                                                <InputLabel id="demo-multiple-name-label">State</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    value={values.State || ""}
                                                    onChange={handleChange('State')}
                                                    onBlur={handleBlur('State')}
                                                    id="State"
                                                    input={<OutlinedInput label="State" />}
                                                    name="State"
                                                    disabled={isSubmitted}
                                                    // helperText={touched.State && errors.State ? errors.State : null}
                                                    error={touched.State && Boolean(errors.State)}
                                                    MenuProps={MenuProps}
                                                >
                                                    {states.map((state, index) => {
                                                        return (
                                                            <MenuItem key={index} value={state.state_code}>{state.state_name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                {touched.State && errors.State ? <FormHelperText>Please select State.</FormHelperText> : null}
                                            </FormControl> 
                                                    </Grid>
                                                   
                                                </Grid>
                                                <Grid container spacing={2} sx={{ mt: 0.2 }}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            required
                                                            autoComplete="NameofBusiness"
                                                            name="NameofBusiness"
                                                            // onChange={handleChange('NameofBusiness')}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                checkCompany(e.target.value);
                                                                handleStampChange(e.target.value)
                                                            }}
                                                            value={values.NameofBusiness || ""}
                                                            // onBlur={checkCompany(values.NameofBusiness)}
                                                            fullWidth
                                                            // size="small"
                                                            id="NameofBusiness"
                                                            label="Name of Business"
                                                            disabled={isSubmitted}
                                                            helperText={touched.NameofBusiness && errors.NameofBusiness ? errors.NameofBusiness : null}
                                                            error={touched.NameofBusiness && Boolean(errors.NameofBusiness)}
                                                        />
                                                        <p style={{ color: '#d32f2f', fontFamily: "Roboto", fontWeight: 400, fontSize: "0.90rem" }}>{checkCompanyStatus === true ? "Company name already exist please use new name" : ''}</p>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            autoComplete="Title"
                                                            name="Title"
                                                            onChange={handleChange('Title')}
                                                            value={values.Title || ""}
                                                            onBlur={handleBlur('Title')}
                                                            fullWidth
                                                            // size="small"
                                                            id="Title"
                                                            label="Title"
                                                            disabled={isSubmitted}
                                                            helperText={touched.Title && errors.Title ? errors.Title : null}
                                                            error={touched.Title && Boolean(errors.Title)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={6} className="registermargin"
                                                        sx={{
                                                            '& .MuiTextField-root': { width: '100%' },
                                                            '& .MuiStack-root': { paddingTop: "7px" }
                                                        }}
                                                    >
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker']} >
                                                                <DatePicker
                                                                    id="YearBusinessStarted"
                                                                    name="YearBusinessStarted"
                                                                    label="Year Business Started *"
                                                                    views={['year']}
                                                                    onChange={(value) => { setFieldValue("YearBusinessStarted", value, true) }}
                                                                    value={values.YearBusinessStarted || ""}
                                                                    onBlur={handleBlur('YearBusinessStarted')}
                                                                    disabled={isSubmitted}
                                                                    minDate={Moment(minDate)}
                                                                    maxDate={Moment(new Date())}
                                                                    disableFuture
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                        <FormHelperText sx={{ color: touched.YearBusinessStarted && errors.YearBusinessStarted ? 'red' : 'inherit' }}>
                                                            {touched.YearBusinessStarted && errors.YearBusinessStarted ? errors.YearBusinessStarted : ''}
                                                        </FormHelperText>
                                                       
                                                    </Grid>
                                                    {refer === 'mfsnhomepage' || refer === 'mfsnloginpage' || refer === 'membersite' ?
                                                        <>
                                                            {!softwarename ?
                                                                <Grid item xs={12} sm={6} mt={1}>
                                                                    <FormControl fullWidth
                                                                        error={touched.SoftwareUsed && Boolean(errors.SoftwareUsed)}
                                                                    >
                                                                        <InputLabel id="demo-multiple-name-label">Software to be Used</InputLabel>
                                                                        <Select
                                                                            required
                                                                            labelId="demo-multiple-name-label"
                                                                            id="demo-multiple-name"
                                                                            input={<OutlinedInput label="Software to be Used" />}
                                                                            name="SoftwareUsed"
                                                                            disabled={isSubmitted}
                                                                            // onChange={handleChange}
                                                                            value={values.SoftwareUsed || ""}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                setSoftwareType(e.target.value); values.othersofttype = "";
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            MenuProps={MenuProps}
                                                                        >
                                                                            {software.map((software,index) => (
                                                                                <MenuItem
                                                                                key={index}
                                                                                value={software.link_name}
                                                                                >
                                                                                    {software.software_name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        {touched.SoftwareUsed && errors.SoftwareUsed ? <FormHelperText>Please select Software Used.</FormHelperText> : null}
                                                                    </FormControl>
                                                                </Grid>
                                                                : ""}
                                                        </> :
                                                        <>
                                                            <Grid item xs={12} sm={6} mt={1}>
                                                                <TextField
                                                                    fullWidth
                                                                    disabled
                                                                    required
                                                                    // size="small"
                                                                    id="MyFreeScoreNowReferralCode"
                                                                    // label="MyFreeScoreNow Referral Code"
                                                                    name="MyFreeScoreNowReferralCode"
                                                                    onChange={handleChange('MyFreeScoreNowReferralCode')}
                                                                    value={refer || ""}
                                                                    onBlur={handleBlur('MyFreeScoreNowReferralCode')}
                                                                    autoComplete="MyFreeScoreNowReferralCode"

                                                                // helperText={touched.MyFreeScoreNowReferralCode && errors.MyFreeScoreNowReferralCode ? errors.MyFreeScoreNowReferralCode : null}
                                                                // error={touched.MyFreeScoreNowReferralCode && errors.MyFreeScoreNowReferralCode ? errors.MyFreeScoreNowReferralCode : null}
                                                                />

                                                            </Grid>
                                                            {!softwarename ?
                                                                <Grid item xs={12} sm={12} mt={2}>
                                                                    <FormControl fullWidth
                                                                        error={touched.SoftwareUsed && errors.SoftwareUsed ? errors.SoftwareUsed : null}
                                                                    >
                                                                        <InputLabel id="demo-multiple-name-label">Software to be Used</InputLabel>
                                                                        <Select
                                                                            required
                                                                            labelId="demo-multiple-name-label"
                                                                            id="demo-multiple-name"
                                                                            input={<OutlinedInput label="Software to be Used" />}
                                                                            name="SoftwareUsed"
                                                                            disabled={isSubmitted}
                                                                            value={values.SoftwareUsed || ""}
                                                                            // onChange={handleChange}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                setSoftwareType(e.target.value); values.othersofttype = "";
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            MenuProps={MenuProps}
                                                                        >
                                                                            {software.map((software,index) => (
                                                                                <MenuItem
                                                                                    value={software.link_name}
                                                                                    key={index}
                                                                                >
                                                                                    {software.software_name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        {touched.SoftwareUsed && errors.SoftwareUsed ? <FormHelperText>Please select Software Used.</FormHelperText> : null}
                                                                    </FormControl>
                                                                </Grid>
                                                                : ""}
                                                        </>
                                                    }
                                                </Grid>


                                                <Grid item xs={12} sm={12}>
                                                    {/* <ReCAPTCHA sitekey="6LdbEeAgAAAAAJWbvVs_zut6CbJb-Eh70-pFOx-J" /> */}

                                                    {/* <reCAPTCHA sitekey='6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe' /> */}
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    

                                                    <Grid item xs={12} sm={12} mt={2} display="none" id="othersofttypes">
                                                        <FormControl fullWidth
                                                        >
                                                            <TextField
                                                                required
                                                                autoComplete="othersofttype"
                                                                name="othersofttype"
                                                                onChange={(e) => { handleChange(e); handleothersoft(e.target.value); }}
                                                                value={values.othersofttype || ""}
                                                                onBlur={handleBlur('othersofttype')}
                                                                disabled={isSubmitted}
                                                                fullWidth
                                                                id="othersofttype"
                                                                label=" Other Software Name "
                                                            />
                                                            <p style={{ color: '#d32f2f', fontFamily: "Roboto", fontWeight: 400, fontSize: "0.90rem" }}>{checkOtherStatus === true ? "Please Enter Software Name" : ''}</p>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
												<Grid container spacing={2} sx={{ mt: 0.2 }}>													
                                                    <Grid item xs={12} sm={6} mb={2} mt={2} sx={{justifyContent:'center',alignItems:'center'}}>
                                                        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                                                            <Grid item>
                                                                <Typography level="h6" sx={{ fontWeight: "500"}} pb={1}>Upload your company logo here </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                &nbsp;<DoubleArrowIcon fontSize='medium' />
                                                            </Grid>
                                                        </Grid>                                                        
                                                        <Typography level="h6" sx={{ fontWeight: "500"}} pb={1}>For <b>FREE</b> Co-Branded Site!</Typography>
                                                        <Typography level="h6" sx={{ fontWeight: "500"}} pb={1}>You may change it later by logging into your account after completing affiliate registration.</Typography>
                                                        <Typography level="h6" sx={{ fontWeight: "500",color:'red'}} pb={1}>NOTE: Image should be 300 x 90 px in dimensions</Typography>
                                                        {/* <Typography level="h6" sx={{ fontWeight: "500",color:'red'}}>2. You may change it later by log in to your account</Typography> */}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} sx={{display:'flex'}} mb={2} mt={2}>
                                                        <Card variant='outlined' style={{width: '100%'}}>
                                                            <CardContent sx={{textAlign:'center',display:'flex',justifyContent:'center',alignItems: 'center',paddingBottom:'0px'}}>             
                                                            <img src={imageUrl} style={{width: '78%'}}/>
                                                            <Button variant="contained"  color="success" disabled={isSubmitted} onClick={()=>{handleUploadLogo()}} sx={{height: 'fit-content',marginLeft:'9px'}}>Edit</Button>
                                                            </CardContent>
                                                        </Card> 
                                                    </Grid>
                                                </Grid>
                                              

                                                <Grid container display="none" id="otpinput">
                                                    <Card sx={{ border: "1px solid red", padding: "2%", marginTop: "3%" }}>
                                                        <Grid item xs={12}>
                                                            <Typography level="body2" fontSize="md">A one-time code has been sent to your email address ({values.Email}) for verification. Please enter the code below to verify your email.</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} mt={1}>
                                                            <TextField
                                                                required
                                                                autoComplete="otpinput"
                                                                name="otpinput"
                                                                onChange={(e) => { handleChange(e); setError(""); }}
                                                                value={values.otpinput || ""}
                                                                fullWidth
                                                                // size="small"
                                                                label="Please enter OTP"
                                                            />
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={5} sx={{ justifyContent: "space-between" }}>
                                                    <Grid item xs sx={{textAlign: { xs: "center", md: "left" }}}>
                                                        <Button
                                                            type="submit"
                                                            onClick={checkEmailStatus === false && checkOtherStatus === false && checkCompanyStatus === false && disable === false  && checkUsaTldStatus == false && emailButton == false? handleSubmit : null}

                                                            variant="contained"
															color="success"
                                                            id="submitbtn"
                                                            label="Submit"
                                                            sx={{ minWidth: 200}}
                                                        >
                                                            {sumbitbtntext}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs sx={{ textAlign: { xs: "center", md: "right" }, marginTop: { xs: "10px", md: "0" } }} display="none" id="editbutton" >
                                                        <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={() => { handleEditChange(); values.otpinput = ""; }}

                                                        >Edit Form</Button>
                                                    </Grid>
                                                </Grid>


                                                {loading}
                                                <Typography sx={{ color: "green" }}>{successMessage}</Typography>
                                                <Typography sx={{ color: "red" }}>{error}</Typography>
                                            </div>
                                        )}
                                    </Formik>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                fullScreen={fullScreen}
                open={opens}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleEmailExitsClose();
                    }
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>Email Address Exists</DialogTitle>
                <DialogContent>
                    <p>
                        Your affiliate application with given email address is under process with MyFreeScoreNow.com. Please contact our support for any questions at +1-888-548-2008.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEmailExitsClose} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog

                fullScreen={fullScreen}
                open={openCongration}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCongrationClose();
                    }
                }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>Congratulations</DialogTitle>
                <DialogContent>
                    <p>
                        You have been successfully enrolled for the ProdigySurge Epic Challenge Offer. You should see the links in your MyLinks section. An email has been also sent to your registered email address with the details.
                        Please login at </p>
                    <p>
                        <a style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }} onClick={() => {
                            localStorage.setItem('origin', "mfsnhomepage");
                            navigate('/login');
                        }}>
                            Login
                        </a>&nbsp;&nbsp;

                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCongrationClose} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen={fullScreen}
                open={openEmailApproved}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleEmailApprovedClose();
                    }
                }}
                aria-labelledby="responsive-dialog-title"

            >
                <DialogTitle>Already an Affiliate!</DialogTitle>
                <DialogContent>
                    <p>
                        You are already an affiliate with MyFreeScoreNow.com.You can still register for the ProdigySurge Epic Challenge offer. Click the button below.
                    </p>
                    <p><Button onClick={() => JoinEpicPro(email)} color="primary" variant="contained">
                        Join Epic Challenge Offer
                    </Button>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEmailApprovedClose} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                    open={uploadLogo}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseUploadLogo();
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <LogoEdit  logoEditURL={handleLogoURL} setOpen={handleCloseUploadLogo}  source="register"  handleEditLogoURLFlag={handleEditLogoURLFlag}/>
                </Dialog>
            <RegisterFooter />

            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <Loader />
                </Backdrop>
            </div>
            {/* loader code end */}

            {/* Account Confirmation Dialog Box start */}
			<Dialog
                open={accountConfirmationOpen}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setAccountConfirmationOpen(false)
                    }
                    }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"  className="back" style={{ display: 'flex', alignItems: 'center',color:'blue' }}>Link your {marketingType} Account </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography level="h6">Do you have an account with {marketingType}? </Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeAccountCofirmation} >
                    No
                </Button>
                <Button onClick={accountConnectConfirmation}  autoFocus variant="contained" color='success' style={{ minWidth: 100 }}>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
            {/* Account Confirmation Dialog Box end */}

			 {/* Account connection Dialog Box start */}
			<Dialog
                open={accountConnectOpen}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setAccountConnectOpen(false)
                    }
                    }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="back" style={{ display: 'flex', alignItems: 'center',color:'blue' }}> Link {marketingType} Account </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography level="h6">Great News! You can now link your {marketingType} account to your affiliate account in one click. Do you want to continue? </Typography>
                </DialogContentText>
                </DialogContent>
                <DialogContent style={{ display: 'flex' }}>
                <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeAccountConnect} >
                    I will do it later
                </Button>
                <Button onClick={handleAccountConfirmation}  autoFocus variant="contained" color='success' style={{ minWidth: 100, marginLeft:'50px' }}>
                    Yes
                </Button>
                </DialogContent>
                <DialogActions style={{ display: 'flex',justifyContent: 'left'}}>
                {/* <Checkbox
                    name="checkboxValue"
                    id="checkboxValue"
                    checked={checkboxValue} 
                    onChange={handleCheckboxChange} 
                    /> <span style={{ fontWeight: 550 }}>Don't Show me again</span> */}
                </DialogActions>
            </Dialog>
            {/* Account connection Dialog Box end */}

            {/* Create Account Confirmation Dialog Box start */}
            <Dialog
                open={createAccountConfirmationOpen}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setCreateAccountConfirmationOpen(false)
                    }
                    }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="back" style={{ display: 'flex', alignItems: 'center',color:'blue' }}>Create {marketingType} Account </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
				<Typography level="h6" >Do you want to create a new account with {marketingType}? </Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeCreateAccountCofirmation} >
                    No
                </Button>
                <Button onClick={createAccount}  autoFocus variant="contained" color='success' style={{ minWidth: 100 }}>
                    Yes, Create Account
                </Button>
                </DialogActions>
                <DialogActions style={{ display: 'flex',justifyContent: 'left'}}>
                {/* <Checkbox
                    name="checkboxValue"
                    id="checkboxValue"
                    checked={checkboxValue} 
                    onChange={handleCheckboxChange} 
                    /> <span style={{ fontWeight: 550 }}>Don't Show me again</span> */}
                </DialogActions>
            </Dialog>
            {/* Create Account Confirmation Dialog Box end */}

			{/* Validate Account Form start */}        
			<Formik
							initialValues={{
								username: '',
								password: '',
								clientConcern: ''
							}}
							validationSchema={accountSchema}
							onSubmit={async(values, { resetForm }) => {
								handleValidateClose();
								setUserName(values.username);
								gUserName = values.username;
								gUserPassword = values.password;
								gClientConcern = values.clientConcern;
								setUserPassword(values.password);
								submitRequest(formValues);
								resetForm();
								// Verifyemail(formValues);
							}}
						>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
								<Dialog
									open={validateopen}
									onClose={(_, reason) => {
										if (reason !== "backdropClick") {
											setValidateOpen(false);
										}
										}}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									fullWidth={fullWidth}
									maxWidth={maxWidth}
								>
									<DialogTitle id="alert-dialog-title" className="back" style={{color:'blue'}}>
										Validate Your {marketingType} Account
									</DialogTitle>
									<DialogContent>
									<Box sx={{ border: 1,p: 1 }}>
										<Grid container item lg={12} md={12} sm={12} xs={12}>
											<TextField
												required
												id="username"
												name='username'
												label="User Name"
												fullWidth
												size="small"
												margin="dense"
												onChange={handleChange('username')}
												value={values.username || ""}
												onBlur={handleBlur('username')}
												helperText={touched.username && errors.username ? errors.username : null}
                                                error={touched.username && errors.username ? errors.username : null}
											/>
											{/* {touched.username && errors.username ? <div className='error'>{errors.username}</div> : null} */}
											
											<TextField
                                                    required
                                                    autoComplete="password"
                                                    name="password"
                                                    type={showPassword ? "text" : "password"}
                                                    onChange={handleChange('password')}
                                                    onBlur={handleBlur('password')}
                                                    fullWidth
                                                    value={values.password || ""}
                                                    margin="normal"
                                                    size="small"
                                                    id="password"
                                                    label="Password"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end"> {
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}>
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    helperText={touched.password && errors.password ? errors.password : null}
                                                    error={touched.password && errors.password ? errors.password : null}
                                                />
										</Grid>
										</Box>
										<Grid container item mt={2} lg={12} md={12} sm={12} xs={12}>
											<FormControl>
												<FormLabel id="demo-row-radio-buttons-group-label" sx={{color:"black"}}>Select a method to import your members into {marketingType} *</FormLabel>
												<RadioGroup
												row
												aria-labelledby="demo-row-radio-buttons-group-label"
												id="clientConcern"
												name="clientConcern"
												value={values.clientConcern || ""}
												onChange={(e) => {
													handleChange(e);
												}}
												>
												<FormControlLabel value="create" control={<Radio />} label={"Import automatically when a new member is enrolled."} />
												<FormControlLabel value="ask" control={<Radio />} label={"Import manually from the Member List."} />
												
												</RadioGroup>
												{touched.clientConcern && errors.clientConcern ? <div className='error'>{errors.clientConcern}</div> : null}
											</FormControl>
										</Grid>
										
										<Grid container item lg={12} md={12} sm={12} xs={12}>
											<Typography level="h6" style={{ color: '#d32f2f'}}>{errorMsg}</Typography>
										</Grid>
									</DialogContent>
									<DialogActions sx={{ display: "flex", justifyContent: "center" }}>
										<Button onClick={handleSubmit}  variant="contained" color="success" sx={{ minWidth: 200 }}>Validate Account</Button>
										<Button onClick={cancelValidation} variant="contained" color="primary" sx={{ minWidth: 200 }}>Back</Button>
									</DialogActions>
								</Dialog>
							)}
			</Formik>
            {/* Validate Account Form End */}

			{/* ask new username & password start */}        
			<Formik
							initialValues={{
								username: '',
								password: '',
								clientConcern: ''
							}}
							validationSchema={accountSchema}
							onSubmit={async(values, { resetForm }) => {
								http.post("/checkUsernameExist", {
									userName:values.username
								})
								.then((res) => {
									if (res.data.success === true) {
										setErrorMsg(res.data.message);
                                        resetForm();
                                        setTimeout(function () {
                                            setErrorMsg("");
                                        }, 5000);
									}else{
										setErrorMsg("");
										handleCreateNewAccountClose();
										setUserName(values.username);
										gUserName = values.username;
										gUserPassword = values.password;
										gClientConcern = values.clientConcern;
										setUserPassword(values.password);
										submitRequest(formValues);
                                        resetForm();
									}
								})
								
								// Verifyemail(formValues);
							}}
						>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
								<Dialog
									open={createAccountOpen}
									onClose={(_, reason) => {
										if (reason !== "backdropClick") {
											setCreateAccountOpen(false);
										}
										}}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									fullWidth={fullWidth}
									maxWidth={maxWidth}
								>
									<DialogTitle id="alert-dialog-title" className="back" style={{ color: 'blue'}}>
										Create New Account
									</DialogTitle>
									<DialogContent>
										<Box sx={{ border: 1,p: 1 }}>
											<Grid container item lg={12} md={12} sm={12} xs={12}>
												<TextField
													required
													id="username"
													name='username'
													label="User Name"
													fullWidth
													size="small"
													margin="dense"
													onChange={handleChange('username')}
													value={values.username || ""}
													onBlur={handleBlur('username')}
												/>
												{touched.username && errors.username ? <div className='error'>{errors.username}</div> : null}
												<TextField
														required
														autoComplete="password"
														name="password"
														type={showPassword ? "text" : "password"}
														onChange={handleChange('password')}
														onBlur={handleBlur('password')}
														fullWidth
														margin="normal"
														size="small"
														id="password"
														label="Enter Your Password"
                                                        value={values.password || ""}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end"> {
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}>
																		{showPassword ? <VisibilityOff /> : <Visibility />}
																	</IconButton>
																}
																</InputAdornment>
															),
														}}
														helperText={touched.password && errors.password ? errors.password : null}
														error={touched.password && errors.password ? errors.password : null}
													/>
											</Grid>
										</Box>				
										<Grid container item mt={2} lg={12} md={12} sm={12} xs={12}>
											<FormControl>
												<FormLabel id="demo-row-radio-buttons-group-label" sx={{color:"black"}}>Select a method to import your members into {marketingType} *</FormLabel>
												<RadioGroup
												row
												aria-labelledby="demo-row-radio-buttons-group-label"
												id="clientConcern"
												name="clientConcern"
												value={values.clientConcern || ""}
												onChange={(e) => {
													handleChange(e);
												}}
												>
												<FormControlLabel value="create" control={<Radio />} label={"Import automatically when a new member is enrolled."} />
												<FormControlLabel value="ask" control={<Radio />} label={"Import manually from the Member List."} />
												
												</RadioGroup>
												{touched.clientConcern && errors.clientConcern ? <div className='error'>{errors.clientConcern}</div> : null}
											</FormControl>
										</Grid>
										<Grid container item lg={12} md={12} sm={12} xs={12}>
											<Typography level="h6" style={{ color: '#d32f2f'}}>{errorMsg}</Typography>
										</Grid>
									</DialogContent>
									<DialogActions sx={{ display: "flex", justifyContent: "center" }}>
										<Button onClick={handleSubmit}  variant="contained" color="success"  sx={{ minWidth: 200 }}>Create</Button>
										<Button onClick={cancelCreateNewAccount} variant="contained" color="primary" sx={{ minWidth: 200 }}>Back</Button>
									</DialogActions>
								</Dialog>
							)}
			</Formik>
            {/* ask new username & password End */}
        </>
    );
}

export default Register;